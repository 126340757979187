<template>
	<div>
		<b-card no-body>
			<b-tabs card>
				<b-tab title="Change Log">
					<b-card>
						<b-table show-empty striped hover :items="items" :fields="fields" :current-page="currentPage"
							:per-page="perPage" :filter="filter" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc"
							:sort-direction="sortDirection" responsive>
							<template v-slot:cell(dateCreated)="row">
								{{ showFormattedDate(row.item.dateCreated) }}
							</template>
							<template v-slot:cell(reasonForUpdate)="row">
								<span class="truncate-text">
									<truncate collapsed-text-class="collapsed" clamp="Show More" :length="50"
										less="Show Less" type="html" :text="breakNotes(getNewNotes(row.item), 50)" />
								</span>
							</template>
						</b-table>

						<b-row>
							<b-col md="8" sm="12" class="my-1">
								<span class="total-display">
									Total: {{ totalRows ? totalRows.toLocaleString() : 0 }}
								</span>
							</b-col>
							<b-col md="4" sm="12" class="my-1">
								<b-pagination align="right" :total-rows="totalRows" :per-page="perPage" v-model="currentPage" class="my-0" />
							</b-col>
						</b-row>
					</b-card>
				</b-tab>
			</b-tabs>
		</b-card>
	</div>
</template>

<script>
// Utils
import { DateUtil } from '@/utils/dateutil';

// DAO
import AuditTrailLogsDAO from '@/database/auditTrailLogs';

// Others
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import truncate from 'vue-truncate-collapsed';
import _ from 'lodash';

export default {
	name: 'asset-accountability-details-view',
	components: {
		Loading,
		truncate
	},
	props: {
		companyAssetPool: {
			type: Object,
			required: true,
		},
	},
	data() {
		return {
			items: [],
			fields: [
				{
					key: 'createdBy',
					label: 'Created By',
					sortable: true,
				},
				{
					key: 'dateCreated',
					label: 'Date Created',
					sortable: true,
				},
				{
					key: 'reasonForUpdate',
					label: 'Reasons for Update',
				},
			],

			currentPage: 1,
			perPage: 10,
			totalRows: 0,
			pageOptions: [5, 10, 15, 25, 50, 100],
			sortBy: null,
			sortDesc: false,
			sortDirection: 'asc',
			filter: null,

			loggedUser: this.$store.getters.loggedUser,
			// Check for loader
			isLoading: false,
		};
	},
	async mounted() {
		let companyAssetPoolId = this.companyAssetPool.id;
		await this.retrieveChangeLog(companyAssetPoolId);
	},
	methods: {
		getNewNotes(item) {
			return item.new.notes ? item.new.notes : '-'
		},
		async retrieveChangeLog(companyAssetPoolId) {
			if (companyAssetPoolId) {
				let param = {
					collection: 'companyAssetPools',
					id: companyAssetPoolId,
				};

				let results = await AuditTrailLogsDAO.getAuditTrailLogs(param);
				this.items = Object.values(results);
				this.totalRows = this.items.length;
			}
		},

		showNumber(value) {
			if (value) {
				return value;
			}
			return 0;
		},

		showValue(value) {
			if (!_.isEmpty(value)) {
				return value;
			}
			return '-';
		},

		showFormattedDate(date) {
			return DateUtil.getFormattedDateWithTime(date);
		},
		breakNotes(notes, length) {
			return notes.length > length ? notes.replace(new RegExp(`(?![^\\n]{1,${length}}$)([^\\n]{1,${length}})\\s`, 'g'), '$1<br>') : notes;
		}
	},
};
</script>

<style scoped>
.update-notes {
	font-style: italic;
}

.numFont {
	font-size: 20px;
}
</style>
