import axios from 'axios';
import config from '@/config/env-constants';


export default {
    baseUrl: 'https://us-central1-ayun-quantity-' + config.currEnv + '.cloudfunctions.net',

    getCompanyAssetPools(currUserId, view, filterBy) {
        let url = `${this.baseUrl}/getCompanyAssetPools`;
        return axios.post(url, {
            currUserId: currUserId,
            view: view,
            filterBy: JSON.stringify(filterBy)
        });
    },
}